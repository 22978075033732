import { useCallback, useState } from 'react';
import { useIsMounted } from '../../hooks/useIsMounted';

export const useRepoCard = (submitStar: () => Promise<unknown>) => {
  const [isStarred, setStarred] = useState(false);
  const isMountedRef = useIsMounted();
  const onStarButtonClick = useCallback(async () => {
    try {
      await submitStar();
      if (isMountedRef.current) {
        setStarred(true);
      }
    } catch (e) {}
    // isMountedRef and submitStar never change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    onStarButtonClick,
    isStarred,
  };
};
