import React from 'react';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { RepoCard } from '../../../view/RepoCard';
import settingsParams from '../settingsParams';
import { useReposContext } from '../../../context/ReposContext';

export const RepoCardList = () => {
  const { repos, submitStar } = useReposContext();
  const settings = useSettings();

  return (
    <div>
      {repos.map((repo, index) => (
        <RepoCard
          key={repo.name}
          repo={repo}
          index={index}
          dataHookPrefix="app-repo-card"
          showIcon={settings.get(settingsParams.showRepoIcon)}
          submitStar={submitStar}
        />
      ))}
    </div>
  );
};
