
  import { getWidgetWrapper } from '@wix/yoshi-flow-editor-runtime/build/esm/WidgetWrapper.js';
  import Widget from '/home/builduser/agent00/work/cd945eb1764b8543/src/components/ItemPage/Widget/index.tsx';
  import {
    HttpProvider,
  } from '@wix/yoshi-flow-editor';

  import {
    withStyles
  } from '@wix/native-components-infra';

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor-runtime/build/esm/i18next/init';



  import stylesParams from '/home/builduser/agent00/work/cd945eb1764b8543/src/components/ItemPage/stylesParams.ts';

  var sentryConfig = {
      DSN: 'https://a4654045010646ebaa760c9a9fe2efbe@sentry.wixpress.com/844',
      id: 'a4654045010646ebaa760c9a9fe2efbe',
      projectName: 'yoshi-flow-editor-example',
      teamName: 'yoshi',
    };

  var UserComponent = getWidgetWrapper({
      initI18n,
      HttpProvider,
      withStylesHoc: withStyles,
    }, Widget, {
    sentryConfig,
    stylesParams,
    name: 'ItemPage',
    localeDistPath: 'assets/locales',
  });

  
    import { hot } from '@wix/component-hot-loader';
    UserComponent = hot(module, UserComponent);
  

  const loadChunks = Widget.loadChunks;

  export default {
    component: UserComponent,
    loadChunks
  };
