import { useEffect, useRef } from 'react';

export const useIsMounted = () => {
  const ref = useRef<boolean>();
  if (ref.current == null) {
    ref.current = true;
  }
  useEffect(() => {
    return () => {
      ref.current = false;
    };
  }, []);

  return ref;
};
