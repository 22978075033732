import React, { useEffect, useMemo } from 'react';
import {
  useBi,
  useEnvironment,
  useExperiments,
  useTranslation,
  WidgetProps,
} from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { Button } from 'wix-ui-tpa/Button';
import { Text } from 'wix-ui-tpa/Text';
import settingsParams from '../settingsParams';
import { useButtonInteraction } from '../../../hooks/useButtonInteraction';
import { Repo } from '../../../model/repo';
import { ReposContextProvider } from '../../../context/ReposContext';
import { classes, st } from './Widget.st.css';
import { RepoCardList } from './RepoCardList';

export interface ControllerProps {
  message: string;
  repos: Repo[];
  submitStar: () => Promise<unknown>;
  getRepos: (page: number) => Promise<Repo[]>;
}

export default ({
  message,
  submitStar,
  repos: initialRepos,
  getRepos,
}: WidgetProps<ControllerProps>) => {
  const settings = useSettings();
  const { experiments } = useExperiments();
  const { t } = useTranslation();
  const { isRTL } = useEnvironment();

  const { isClicked, handleClick, repos } = useButtonInteraction({
    interactionName: 'click_me_button',
    initialRepos,
    getRepos,
  });

  const reposContext = useMemo(() => ({ repos, submitStar }), [
    repos,
    submitStar,
  ]);

  const bi = useBi();
  const { appDefinitionId, widgetId: componentId } = useEnvironment();

  useEffect(() => {
    bi.templateWidgetLoaded({
      appDefinitionId,
      componentId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ReposContextProvider value={reposContext}>
      <div className={st(classes.root, {})} data-hook="ItemPage-wrapper">
        <div className={classes.header}>
          <h2 data-hook="app-title">
            {message}{' '}
            {t('app.widget.welcome', {
              greetingsText: settings.get(settingsParams.greetingsText),
            })}
          </h2>
        </div>
        {experiments.enabled('specs.test.ShouldHideButton') ? null : (
          <Button
            data-hook="app-button"
            onClick={handleClick}
            className={classes.mainButton}
          >
            Click me
          </Button>
        )}

        <Text>{isRTL ? '👉' : '👈'}</Text>

        {isClicked && (
          <div data-hook="app-button-was-clicked">
            <Text>Button was clicked!</Text>
          </div>
        )}

        <RepoCardList />
      </div>
    </ReposContextProvider>
  );
};
