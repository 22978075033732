import { useFedopsLogger, useSentry } from '@wix/yoshi-flow-editor';
import { useCallback, useState } from 'react';
import { Repo } from '../model/repo';
import { useIsMounted } from './useIsMounted';

export const useButtonInteraction = ({
  interactionName,
  getRepos,
  initialRepos,
}: {
  getRepos: (page: number) => Promise<Repo[]>;
  interactionName: string;
  initialRepos: Repo[];
}) => {
  const fedopsLogger = useFedopsLogger();
  const sentry = useSentry();

  const isMounted = useIsMounted();
  const [isClicked, setIsClicked] = useState(false);
  const [repos, setRepos] = useState<Repo[]>(initialRepos);

  const handleClick = useCallback(async () => {
    fedopsLogger.interactionStarted(interactionName);
    try {
      const result = await getRepos(2);
      if (isMounted.current) {
        setRepos([...repos, ...result]);
      }
      await new Promise((_, reject) => {
        setTimeout(() => reject(new Error('Ooops')), 5);
      });
    } catch (e) {
      console.error(e);
      sentry.captureException(e);
    }
    fedopsLogger.interactionEnded(interactionName);
    if (isMounted.current) {
      setIsClicked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    repos,
    isClicked,
    handleClick,
  };
};
