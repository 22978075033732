import { getAppGreetingsPretext } from '@services/getAppGreetingsPretext';
import {
  createSettingsParams,
  SettingsParamType,
} from '@wix/yoshi-flow-editor/tpa-settings';
import applicationJson from '../../../.application.json';

export type ISettingsParams = {
  greetingsText: SettingsParamType.Text;
  showRepoIcon: SettingsParamType.Boolean;
};

export default createSettingsParams<ISettingsParams>({
  greetingsText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      `${t(getAppGreetingsPretext())} ${applicationJson.appName}`,
  },
  showRepoIcon: {
    getDefaultValue: () => true,
  },
});
