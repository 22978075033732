import React, { memo } from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';
import { Repo } from '../../../model/repo';
import { ErrorBoundary } from './ErrorBoundary';
import ItemPage from './ItemPage';

export interface ControllerProps {
  message: string;
  repos: Repo[];
  submitStar: () => Promise<unknown>;
  getRepos: (page: number) => Promise<Repo[]>;
}

export default memo<WidgetProps<ControllerProps>>((props) => {
  return (
    <ErrorBoundary>
      <ItemPage {...props} />
    </ErrorBoundary>
  );
});
