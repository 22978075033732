import React from 'react';

interface ErrorBoundaryState {
  error?: string;
}

export class ErrorBoundary extends React.Component {
  state: ErrorBoundaryState = {};

  static getDerivedStateFromError(e: Error) {
    return { error: e.toString() };
  }

  render() {
    if (this.state.error) {
      return <h1>ItemPage ErrorBoundary - {this.state.error}</h1>;
    }

    return this.props.children;
  }
}
