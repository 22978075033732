import { createContext, useContext } from 'react';
import { Repo } from '../model/repo';

export interface ReposContext {
  submitStar: () => Promise<unknown>;
  repos: Repo[];
}

const Context = createContext<ReposContext>({
  submitStar: async () => {},
  repos: [],
});

export const ReposContextProvider = Context.Provider;

export const useReposContext = () => useContext(Context);
