import { NewCard } from 'wix-ui-tpa/NewCard';
import React from 'react';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import { IconButton } from 'wix-ui-tpa/IconButton';
import { Repo } from '../../model/repo';
import { classes } from './RepoCard.st.css';
import { Star } from './Star';
import { useRepoCard } from './useRepoCard';

export const RepoCard = ({
  repo,
  index,
  dataHookPrefix = 'repoCard',
  showIcon,
  submitStar,
}: {
  repo: Repo;
  dataHookPrefix?: string;
  index: number;
  showIcon: boolean;
  submitStar: () => Promise<unknown>;
}) => {
  const { onStarButtonClick, isStarred } = useRepoCard(submitStar);

  return (
    <div data-hook={`${dataHookPrefix}-${index}`} className={classes.root}>
      <NewCard className={classes.repoCard}>
        <NewCard.Container className={classes.info}>
          <div className={classes.header}>
            <Text typography={TYPOGRAPHY.smallTitle}>{repo.name}</Text>
            {showIcon && (
              <IconButton
                data-hook={`${dataHookPrefix}-${index}-star-button`}
                onClick={onStarButtonClick}
                icon={<Star filled={isStarred} />}
                className={isStarred ? 'starred' : undefined}
              />
            )}
          </div>
          {repo.description}
        </NewCard.Container>
      </NewCard>
    </div>
  );
};
